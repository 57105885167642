<template>
  <v-container fluid grid-list-xl class="p-0">
    <v-layout wrap>
      <v-flex xs12>
        <div class="headline">Creme de la creme</div>
        <span class="subtitle-1 light-grey">Alle de nørdede data</span>
      </v-flex>

      <v-flex xs12 sm6 md4>
        <stat-widget
          icon="fal fa-clock"
          :title="clickConversionTime.days + ' dage'"
          subtitle="Konverteringstid"
        ></stat-widget>
      </v-flex>

      <v-flex xs12 sm6 md4>
        <stat-widget
          icon="fal fa-recycle"
          :title="nonCanceledSubscriptions - managed"
          subtitle="Abonnementer"
        ></stat-widget>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <stat-widget
          icon="fal fa-file-signature"
          :title="managed"
          subtitle="Managed"
        ></stat-widget>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card class="flex-card" height="100%">
          <v-card-title>
            <div>
              <div class="headline">Lifetime stats</div>
              <span class="subtitle-1 light-grey"
                >Data siden projekt start</span
              >
            </div>
          </v-card-title>
          <v-card-text class="grow">
            <v-data-table
              hide-default-footer
              hide-default-header
              :items="statsAsArray"
              :items-per-page="10000"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.text">
                    <td>
                      {{ item.text }}
                    </td>
                    <td class="text-right">{{ item.data | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card>
          <v-card-title>
            <div>
              <div class="headline">Salg pr. ugedag</div>
              <span class="subtitle-1 light-grey"
                >Hvilke ugedage får flest salg</span
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :headers="[
                { text: 'Ugedag', sortable: false },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="dayStats"
              :items-per-page="10000"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.day_name">
                    <td>
                      {{ item.day_name }}
                    </td>
                    <td>{{ item.sales | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card>
          <v-card-title>
            <div>
              <div class="headline">Salg pr. tidspunkt</div>
              <span class="subtitle-1 light-grey"
                >Alle tidspunkter er i UTC +0! Dansk vintertid er +1 og
                sommertid +2.</span
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :headers="[
                { text: 'Tidspunkt', sortable: false },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="timeStats"
              :items-per-page="10000"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.time_interval">
                    <td>
                      {{ item.time_interval }}
                    </td>
                    <td>{{ item.sales | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card>
          <v-card-title>
            <div>
              <div class="headline">Kategori</div>
              <span class="subtitle-1 light-grey"
                >Alle data er taget fra de kategorier der er sat på
                kampagner</span
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :headers="[
                { text: 'Kategori', value: 'display_name' },
                { text: 'Salg', value: 'sales' }
              ]"
              :items="categoryStats"
              :items-per-page="10000"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.display_name">
                    <td>
                      <v-icon class="mr-2" small>{{ item.icon }}</v-icon>
                      {{ item.display_name }}
                    </td>
                    <td>{{ item.sales | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import { mapActions, mapState } from "vuex";
import StatWidget from "../../widgets/StatWidget";

export default {
  components: { StatWidget },
  filters: { local_numbers },
  data: () => ({
    nonCanceledSubscriptions: 0,
    managed: 0,
    stats: {},
    clickConversionTime: { days: 0 },
    salesPerDayAndTime: [],
    categoryStats: []
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    statsAsArray() {
      if (!this.stats.clicks) {
        return [];
      }

      return [
        { text: "Klik", data: this.stats.clicks },
        { text: "Unikke klik", data: this.stats.unique_clicks },
        { text: "Salg", data: this.stats.sales },
        { text: "Konvertering", data: this.stats.conversion },
        { text: "Omsætning", data: this.stats.value },
        { text: "Kommission", data: this.stats.commission }
      ];
    },
    dayStats() {
      let result = [];

      this.cloneDeep(this.salesPerDayAndTime).reduce(function(res, value) {
        // If not in results add it.
        if (!res[value.day_number]) {
          res[value.day_number] = value;

          result.push(res[value.day_number]);
        }

        // Sum sales
        res[value.day_number].sales += value.sales;

        // Store in var to only display max 2 decimals
        let commission = res[value.day_number].commission + value.commission;

        // Max 2 decimals
        res[value.day_number].commission = Math.round(commission * 100) / 100;
        return res;
      }, {});

      return result.sort((a, b) => (a.sales > b.sales ? -1 : 0));
    },
    timeStats() {
      let result = [];

      this.cloneDeep(this.salesPerDayAndTime).reduce(function(res, value) {
        // If not in results add it.
        if (!res[value.time_interval]) {
          res[value.time_interval] = value;

          result.push(res[value.time_interval]);
        }

        // Sum sales
        res[value.time_interval].sales += value.sales;

        // Store in var to only display max 2 decimals
        let commission = res[value.time_interval].commission + value.commission;

        // Max 2 decimals
        res[value.time_interval].commission =
          Math.round(commission * 100) / 100;
        return res;
      }, {});

      return result.sort((a, b) => (a.sales > b.sales ? -1 : 0));
    }
  },
  created() {
    const businessSubscriptionParams = {
      has_subscription: true,
      has_subscription_canceled: false,
      page: 1,
      limit: 100
    };
    this.getBusinesses(businessSubscriptionParams).then(response => {
      this.nonCanceledSubscriptions = response.total;
    });
    const params = {
      is_managed: true,
      page: 1,
      limit: 100
    };
    this.getBusinesses(params).then(response => {
      this.managed = response.total;
    });
    this.getStats({ start_date: "2018-01-01", end_date: "2030-01-01" }).then(
      response => {
        this.stats = response[0];
      }
    );
    this.getClickConversionTime().then(response => {
      this.clickConversionTime = response;
    });
    this.getSalesPerDayAndTime().then(response => {
      this.salesPerDayAndTime = response;
    });
    this.getCategoryStats().then(response => {
      this.categoryStats = response;
    });
  },
  methods: {
    ...mapActions("moderator", [
      "getClickConversionTime",
      "getCategoryStats",
      "getSalesPerDayAndTime"
    ]),
    ...mapActions("core", ["getBusinesses", "getStats"])
  }
};
</script>
