<template>
  <v-card :outlined="bordered || flat">
    <v-card-text class="pa-0">
      <v-container class="p-3">
        <v-layout row wrap>
          <v-flex xs3 class="text-left">
            <v-icon size="40px">{{ icon }}</v-icon>
          </v-flex>
          <v-flex xs9>
            <div class="display-1 font-weight-bold text-right primary--text">
              <div style="position: relative; overflow: hidden; z-index: 1;">
                {{ title }}
                <v-overlay
                  v-if="blur"
                  absolute
                  :value="true"
                  color="white"
                  class="overlay-blur"
                >
                  <v-btn
                    color="success"
                    x-small
                    @click="setShowSubscriptionDialog(true)"
                    >Upgrade</v-btn
                  >
                </v-overlay>
              </div>
            </div>
            <h3 class="subheading text-right primary--text">
              {{ subtitle }}
              <v-tooltip v-if="tooltip" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1" color="primary"
                    >fal fa-question-circle</v-icon
                  >
                </template>
                <span>{{ tooltip }}</span>
              </v-tooltip>
            </h3>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
      required: false
    },
    bordered: {
      type: Boolean,
      default: false,
      required: false
    },
    blur: {
      type: Boolean,
      default: false,
      required: false
    },
    color: {
      type: String,
      default: "secondary",
      required: false
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: [String, Number],
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    tooltip: {
      required: false,
      default: ""
    }
  },
  data: () => ({}),
  methods: {
    ...mapActions("subscriptions", ["setShowSubscriptionDialog"])
  }
};
</script>
