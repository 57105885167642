var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"headline"},[_vm._v("Creme de la creme")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Alle de nørdede data")])]),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('stat-widget',{attrs:{"icon":"fal fa-clock","title":_vm.clickConversionTime.days + ' dage',"subtitle":"Konverteringstid"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('stat-widget',{attrs:{"icon":"fal fa-recycle","title":_vm.nonCanceledSubscriptions - _vm.managed,"subtitle":"Abonnementer"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('stat-widget',{attrs:{"icon":"fal fa-file-signature","title":_vm.managed,"subtitle":"Managed"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-card',{staticClass:"flex-card",attrs:{"height":"100%"}},[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Lifetime stats")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Data siden projekt start")])])]),_c('v-card-text',{staticClass:"grow"},[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.statsAsArray,"items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.text},[_c('td',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.data)))])])}),0)]}}])})],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Salg pr. ugedag")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Hvilke ugedage får flest salg")])])]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[
              { text: 'Ugedag', sortable: false },
              { text: 'Salg', value: 'sales' }
            ],"items":_vm.dayStats,"items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.day_name},[_c('td',[_vm._v(" "+_vm._s(item.day_name)+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.sales)))])])}),0)]}}])})],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Salg pr. tidspunkt")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Alle tidspunkter er i UTC +0! Dansk vintertid er +1 og sommertid +2.")])])]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[
              { text: 'Tidspunkt', sortable: false },
              { text: 'Salg', value: 'sales' }
            ],"items":_vm.timeStats,"items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.time_interval},[_c('td',[_vm._v(" "+_vm._s(item.time_interval)+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.sales)))])])}),0)]}}])})],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Kategori")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Alle data er taget fra de kategorier der er sat på kampagner")])])]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[
              { text: 'Kategori', value: 'display_name' },
              { text: 'Salg', value: 'sales' }
            ],"items":_vm.categoryStats,"items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.display_name},[_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.display_name)+" ")],1),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.sales)))])])}),0)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }